import "core-js/modules/web.dom-collections.iterator.js";
export default {
  data: () => ({
    formErrors: [],
    errorMessages: null,
    isDirty: {}
  }),
  computed: {
    computeError() {
      const error = this.formErrors.some(error => (error === null || error === void 0 ? void 0 : error.status) === true);
      this.$emit("hasFormErrors", error);
      return error;
    }
  },
  created() {
    this.clearState();
  },
  methods: {
    handleError(name, val) {
      var _this$formErrors;
      this.formErrors = (_this$formErrors = this.formErrors) === null || _this$formErrors === void 0 ? void 0 : _this$formErrors.filter(error => error.name !== name);
      this.formErrors.push({
        name: name,
        status: val
      });
    },
    _resetForms(field, fieldKey) {
      if (fieldKey) this.isDirty[field][fieldKey] = null;else Object.entries(this.isDirty[field]).forEach(_ref => {
        let [key] = _ref;
        this.isDirty[field][key] = null;
      });
    },
    _touchForms(field) {
      const computeErrors = (objectKey, objectValue, parentKey) => {
        const context = this;
        return objectValue.reduce((filtered, rule) => {
          const isError = rule.call(this, parentKey ? context._data[parentKey][objectKey] : context._data[objectKey]);
          if (typeof isError !== "boolean") filtered.push({
            message: isError
          });
          return filtered;
        }, []);
      };
      if (field) {
        const selectedField = this.$options.rules[field];
        Object.entries(selectedField).forEach(_ref2 => {
          var _computeErrors;
          let [key, value] = _ref2;
          this.isDirty = {
            ...this.isDirty,
            [field]: {
              ...(this.isDirty ? this.isDirty[field] : null),
              [key]: Boolean((_computeErrors = computeErrors(key, value, field)) === null || _computeErrors === void 0 ? void 0 : _computeErrors.length)
            }
          };
        });
        return;
      }
      Object.entries(this.$options.rules).forEach(_ref3 => {
        let [key, parentValue] = _ref3;
        if (key === "isDirty") return;
        if (Array.isArray(parentValue)) {
          var _computeErrors2;
          this.isDirty = {
            ...this.isDirty,
            [key]: Boolean((_computeErrors2 = computeErrors(key, parentValue)) === null || _computeErrors2 === void 0 ? void 0 : _computeErrors2.length)
          };
          return;
        }
        Object.entries(parentValue).forEach(_ref4 => {
          var _computeErrors3;
          let [childKey, value] = _ref4;
          this.isDirty = {
            ...this.isDirty,
            [key]: {
              ...this.isDirty[key],
              [childKey]: Boolean((_computeErrors3 = computeErrors(childKey, value, key)) === null || _computeErrors3 === void 0 ? void 0 : _computeErrors3.length)
            }
          };
        });
      });
    },
    clearState() {
      Object.entries(this.$options.rules).forEach(_ref5 => {
        let [key, parentValue] = _ref5;
        if (key === "isDirty") return;
        if (Array.isArray(parentValue)) {
          this.isDirty = {
            ...this.isDirty,
            [key]: null
          };
          return;
        }
        Object.entries(parentValue).forEach(_ref6 => {
          let [childKey] = _ref6;
          this.isDirty = {
            ...this.isDirty,
            [key]: {
              ...this.isDirty[key],
              [childKey]: null
            }
          };
        });
      });
    }
  }
};